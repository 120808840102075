<template>
  <Form ref="formInline" :model="formInline">
    <div class="layout">
      <Layout>
        <Content>
          <Card style="width: 480px">
            <p slot="title">盛望医疗/行为测评管理系统</p>
            <FormItem prop="user">
              <i-Input
                type="text"
                size="large"
                icon="ios-person-outline"
                v-model="formInline.user"
                placeholder="请输入您的登录账号"
              >
                <span slot="prepend">账号:</span>
              </i-Input>
            </FormItem>
            <FormItem prop="password">
              <i-Input
                type="password"
                password
                size="large"
                icon="ios-lock-outline"
                v-model="formInline.password"
                placeholder="请输入您的密码"
              >
                <span slot="prepend">密码:</span>
              </i-Input>
            </FormItem>
            <FormItem>
              <Button type="primary" :loading="loading" @click="handleSubmit('formInline')">登录</Button>
            </FormItem>
          </Card>
        </Content>
      </Layout>

      <!-- 页脚的标签 -->
      <div class="page-login--content-footer">
        <!--  -->
        <p class="page-login--content-footer-copyright">
          <span class>技术支持：绘心砚心理SaaS·专注心理健康的技术服务商</span>
        </p>
        <!-- <p class="page-login--content-footer-options">
          <a href="#">帮助</a>
          <a href="#">隐私</a>
          <a href="#">条款</a>
        </p>-->
      </div>
    </div>
  </Form>
</template>
<script>
import cookies from "js-cookie";
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      formInline: {
        user: "",
        password: "",
      },
    };
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      if (this.formInline.user == "") {
        this.$Message.error({
          background: true,
          content: "请输入您的用户名!",
        });
      } else {
        if (this.formInline.password == "") {
          this.$Message.error({
            background: true,
            content: "请输入您的密码!",
          });
        } else {
          this.$post("/Login/LoginById", {
            LoginName: this.formInline.user,
            LoginPwd: this.formInline.password,
          }).then((res) => {
            if (res.data.code == false) {
              this.loading = false;
              this.$Message.error({
                background: true,
                content: res.data.msg,
              });
            } else {
              this.loading = false;
              // 存储token 时间为1天
              cookies.set("token", res.data.token.token, {
                expires: 1,
              });
              // localStorage.setItem("token", res.data.response.token);
              // localStorage.setItem("token", res.data.token.token);
              localStorage.setItem("roleId", res.data.response.roleId);
              localStorage.setItem("adminName", res.data.response.realName);
              localStorage.setItem("adminId", res.data.response.adminId);
              this.$Message.success({
                background: true,
                content: res.data.msg,
              });

              let key = cookies.get("token");
              // let key = localStorage.getItem("token");
              if (key) {
                this.$router.push({
                  path: "/Welcome",
                });
              } else {
              }
            }
          });
        }
      }
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped="scoped">
.layout {
  padding-top: 10rem;
}

.ivu-layout {
  background: #fff;
}

.ivu-layout-content {
  margin: 0px auto;
}

// footer
.page-login--content-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 1em 0;
  .page-login--content-footer-locales {
    padding: 0px;
    margin: 0px;
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #606266;
    a {
      color: #606266;
      margin: 0 0.5em;
      &:hover {
        color: #606266;
      }
    }
  }
  .page-login--content-footer-copyright {
    padding: 0px;
    margin: 0px;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #606266;
    a {
      color: #606266;
    }
  }
  .page-login--content-footer-options {
    padding: 0px;
    margin: 0px;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    a {
      color: #606266;
      margin: 0 1em;
    }
  }
  .page-login-span-margin {
    display: inline-block;
    margin: 0 5px;
  }
  .page-login-span {
    display: inline-block;
    width: 30px;
    height: 2px;
    background-color: #888888;
  }
}
</style>
